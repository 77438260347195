import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Meta from "../../components/content/Meta";
import Container from "../../components/layout/Container";
import ArticleContent from '../../components/content/ArticleContent';
import { SettingsContext } from "../../context/SettingsContext";

export const query = graphql`
  query ArticleQuery($contentful_id: String!) {
    pageData: contentfulArticle(
      contentful_id: { eq: $contentful_id }
    ) {
      title
      slug
      category {
        title
        slug
      }
      image {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
        fixed(quality: 80, width: 1200) {
          src
        }
      }
      date
      excerpt {
        excerpt
      }
      content {
        json
      }
      metadata {
        ...Metadata
      }
    }
  }
`;

const Article = ({ data, pathContext }) => {
  const { setSettings } = useContext(SettingsContext);

  if (!data || !data.pageData) return null;

  const { metadata = {}, ...content } = data.pageData;

  useEffect(() => {
    if (pathContext.settings) {
      setSettings(pathContext.settings);
    }
  }, []);

  return (
    <Layout pathContext={pathContext}>
      <Meta
        defaults={pathContext.settings.defaultMetadata}
        meta={{
          title: `${content.title} | ${content.category.title} - JVAT`,
          ogTitle: `${content.title} | ${content.category.title} - JVAT`,
          description: { description: content.excerpt.excerpt },
          ogDescription: {ogDescription: content.excerpt.excerpt },
          ogImage: content.image,
          twitterImage: content.image,
          twitterDescription: {twitterDescription: content.excerpt.excerpt },
          ...metadata,
        }}
      />
      <Container>
        <ArticleContent {...content} />
      </Container>
    </Layout>
  );
};

export default Article;
