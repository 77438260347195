import styled from 'styled-components';

const ArticleContent = styled.article`
    position: relative;
    padding: 300px 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
        padding: 240px 0;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        padding: 180px 0;
    }

    .heading {
        position: relative;
        border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey1};
        padding-bottom: 40px;
        margin-bottom: 40px;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            margin-bottom: 20px;
        }
        
        h2 {
            font-family: ${({ theme }) => theme.fonts.helveticaBold};
            text-transform: uppercase;
            font-size: 90px;
            line-height: 88px;
            letter-spacing: -1.7px;
            white-space: pre-wrap;
            margin-bottom: 40px;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                font-size: 50px;
                line-height: 50px;
            }

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
                font-size: 40px;
                line-height: 40px;
            }
        }

        .back {
            display: flex;
            align-items: center;
            font-size: 19px;
            line-height: 30px;
            letter-spacing: 1.8px;
            font-family: ${({ theme }) => theme.fonts.rajdhaniSemiBold};
            text-decoration: none;
            text-transform: uppercase;
            

            &:hover {
                svg {
                    transform: rotateY(180deg) translateX(20px);
                }
            }

            svg {
                transition: transform 0.2s ease;
                transform: rotateY(180deg);
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }

    .inner {
        display: flex;

        @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
            flex-direction: column;
        }

        .category-list {
            flex: 1;
        }

        .content {
            flex: 3;
            padding-left: 120px;
            position: relative;
            margin: 0 auto;
            font-size: 20px;
            line-height: 33px;
            white-space: normal;
            word-break: break-word;

            @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                padding-left: 0;
                font-size: 18px;
                line-height: 30px;
            }

            .gatsby-image-wrapper {
                position: relative;
                max-width: 100%;
                max-height: 550px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            h1 {
                font-size: 44px;
                line-height: 50px;
                letter-spacing: -1px;
                margin: 60px 0;
                text-transform: uppercase;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    font-size: 36px;
                    line-height: 46px;
                    padding-left: 0;
                }
            }

            h6 {
                font-size: 32px;
                line-height: 46px;
                letter-spacing: -0.6px;
                margin: 60px 0;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    font-size: 28px;
                    line-height: 38px;
                }
            }

            p {
                padding: 10px 0;

                b {
                    font-family: ${({ theme }) => theme.fonts.poppinsBold};
                }
            }

            a {
                display: inline-block;
            }

            i {
                font-style: normal;
                font-size: 16px;
            }

            hr {
                margin: 20px 0;
            }

            img {
                max-width: 100%;
                margin: 20px auto;
            }

            ul, ol {
                li {
                    p {
                        margin: 0;
                        padding: 0;
                    }
                }
            }

            blockquote {
                position: relative;
                padding: 60px 0;
                margin: 0;

                @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                    padding: 40px 0;
                }

                p {
                    font-size: 30px;
                    line-height: 40px;
                    margin: 0;
                    padding: 0;

                    @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
                        font-size: 26px;
                        line-height: 36px;
                    }
                }
            }
        }
    }
`

export default {
    ArticleContent
}