import React, { useContext, useEffect } from "react";
import Image from 'gatsby-image';
import { useTheme } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { HeaderContext } from '../../../context/HeaderContext';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
  contentfulRichTextBlockquoteWithQuoteSVG
} from "../../../utils/helpers";
import S from "./styles";
import { ContentfulArticle } from '../../../types';
import ArticleCategories from '../ArticleCategories';
import AnimatedHeading from "../../common/AnimatedHeading";
import { SettingsContext } from "../../../context/SettingsContext";
import ArrowIcon from "../../common/ArrowIcon";

export interface ArticleContentProps extends ContentfulArticle {
  [prop: string]: any;
}

const ArticleContent = ({ title, slug, category, image, date, content, ...rest }: ArticleContentProps) => {
  const { setHeaderColor, setInitialHeaderColor } = useContext(HeaderContext);
  const { settings } = useContext(SettingsContext);
  const theme = useTheme();

  useEffect(() => {
    setHeaderColor("light");
    setInitialHeaderColor("light");
  }, []);

  return (
    <S.ArticleContent {...rest}>
      <div className="heading">
        <h2><AnimatedHeading colourScheme="light">{`Articles \n& Insights`}</AnimatedHeading></h2>
        <AniLink className="back" cover direction="up" bg={theme.colors.darkGrey1} to={settings.articlesPageSlug}><ArrowIcon /> Back</AniLink>
      </div>
      <div className="inner">
        <ArticleCategories className="category-list" />
        <div className="content">
          <div className="image">
            <Image fluid={image.fluid} />
          </div>
          <div className="title">
            <h1>{title}</h1>
          </div>
          {documentToReactComponents(content.json, {
            renderNode: {
              ...contentfulRichTextLinks({ pageMap: settings.pageMap }),
              ...contentfulRichTextInlineImage,
              ...contentfulRichTextBlockquoteWithQuoteSVG({ colourScheme: "light" })
            },
          })}
        </div>
      </div>
    </S.ArticleContent>
  );
};

export default ArticleContent;
